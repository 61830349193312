<template>
  <createLocationSponsorAccount />
</template>

<script>
import createLocationSponsorAccount from "@/components/users/createLocationSponsorAccount.vue";

export default {
  components: {
    createLocationSponsorAccount,
  },
};
</script>
